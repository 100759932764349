type KeyWordData = {
  [department: string]: string[];
};

export const DBkeywords: KeyWordData = {
  경영학과: ['회계', '경영학', '제품과 서비스', '사용자 경험', '소비자', '브랜드', '전문가'],
  컴퓨터학과: [
    '데이터베이스',
    '웹 개발',
    '알고리즘',
    '자연어처리',
    '협업',
    '해결법',
    '창업 프로그램',
    '파이썬',
    '융합',
  ],
  통계학과: [
    '통계학',
    'R 프로그래밍',
    '프로젝트 활동',
    '시각화',
    '수리통계학',
    'SPSS 프로그래밍',
    'SAS 프로그래밍',
    '통계적 데이터 과학',
  ],
};
