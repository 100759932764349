export const majorAllList = [
  { value1: '가정교육과', value2: '사범대학' },
  { value1: '간호학과', value2: '간호대학' },
  { value1: '건축사회환경공학부', value2: '공과대학' },
  { value1: '건축학과', value2: '공과대학' },
  { value1: '경영학과', value2: '경영대학' },
  { value1: '경제학과', value2: '정경대학' },
  { value1: '교육학과', value2: '사범대학' },
  { value1: '국어교육과', value2: '사범대학' },
  { value1: '국어국문학과', value2: '문과대학' },
  { value1: '국제학부', value2: '국제대학' },
  { value1: '글로벌한국융합학부', value2: '국제대학' },
  { value1: '기계공학부', value2: '공과대학' },
  { value1: '노어노문학과', value2: '문과대학' },
  { value1: '데이터과학과', value2: '정보대학' },
  { value1: '독어독문학과', value2: '문과대학' },
  { value1: '디자인조형학부', value2: '디자인조형학부' },
  { value1: '물리학과', value2: '이과대학' },
  { value1: '미디어학부', value2: '미디어학부' },
  { value1: '바이오시스템의과학부', value2: '보건과학대학' },
  { value1: '바이오의공학부', value2: '보건과학대학' },
  { value1: '반도체공학과', value2: '공과대학' },
  { value1: '보건정책관리학부', value2: '보건과학대학' },
  { value1: '보건환경융합과학부', value2: '보건과학대학' },
  { value1: '불어불문학과', value2: '문과대학' },
  { value1: '사이버국방학과', value2: '스마트보안학부' },
  { value1: '사학과', value2: '문과대학' },
  { value1: '사회학과', value2: '문과대학' },
  { value1: '산업경영공학부', value2: '공과대학' },
  { value1: '생명공학부', value2: '생명과학대학' },
  { value1: '생명과학부', value2: '생명과학대학' },
  { value1: '서어서문학과', value2: '문과대학' },
  { value1: '수학과', value2: '이과대학' },
  { value1: '수학교육과', value2: '사범대학' },
  { value1: '스마트모빌리티학부', value2: '스마트모빌리티학부' },
  { value1: '스마트보안학부', value2: '스마트보안학부' },
  { value1: '식품공학과', value2: '생명과학대학' },
  { value1: '식품자원경제학과', value2: '생명과학대학' },
  { value1: '신소재공학부', value2: '공과대학' },
  { value1: '심리학부', value2: '심리학부' },
  { value1: '언어학과', value2: '문과대학' },
  { value1: '역사교육과', value2: '사범대학' },
  { value1: '영어교육과', value2: '사범대학' },
  { value1: '영어영문학과', value2: '문과대학' },
  { value1: '융합에너지공학과', value2: '공과대학' },
  { value1: '의과대학', value2: '의과대학' },
  { value1: '일어일문학과', value2: '문과대학' },
  { value1: '자유전공학부', value2: '자유전공학부' },
  { value1: '전기전자공학부', value2: '공과대학' },
  { value1: '정치외교학과', value2: '정경대학' },
  { value1: '중어중문학과', value2: '문과대학' },
  { value1: '지구환경과학과', value2: '이과대학' },
  { value1: '지리교육과', value2: '사범대학' },
  { value1: '차세대통신학과', value2: '공과대학' },
  { value1: '철학과', value2: '문과대학' },
  { value1: '체육교육과', value2: '사범대학' },
  { value1: '컴퓨터학과', value2: '정보대학' },
  { value1: '통계학과', value2: '정경대학' },
  { value1: '한국사학과', value2: '문과대학' },
  { value1: '한문학과', value2: '문과대학' },
  { value1: '행정학과', value2: '정경대학' },
  { value1: '화공생명공학과', value2: '공과대학' },
  { value1: '화학과', value2: '이과대학' },
  { value1: '환경생태공학부', value2: '생명과학대학' },
];
